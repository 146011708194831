/* sidebar.scss */
.logo {
  margin-bottom: 20px;
  text-align: center;

  img {
    max-width: 100px;
    height: auto;
  }
}

h3 {
  color: #f5f5f5;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
}

.button {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: #f5f5f5;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  background-color: #16213e;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0f3460;
  }

  &.active {
    background-color: #0f3460; /* Highlight color for active state */
    font-weight: bold; /* Optional: make the text bold */
  }

  .text {
    margin-left: 10px;
  }
}

.flex {
  flex-grow: 1;
}

.menu:last-of-type {
  margin-top: auto;
  padding-bottom: 30px;
}

.dropdown {
  position: relative;
  margin-bottom: 20px;

  .dropdown-button {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: #f5f5f5;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    background-color: #16213e;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0f3460;
    }
  }

  .dropdown-content {
    position: absolute;
    background-color: #1a1a2e;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    width: 100%;
    z-index: 1;

    .button {
      padding: 10px 15px;
      color: #f5f5f5;
      text-decoration: none;
      display: block;
      background-color: #16213e;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0f3460;
      }
    }
  }
}