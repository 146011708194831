li {
    list-style: none; /* Remove bullet points */
    padding-left: 10px; /* Add space on the left */
    padding-right: 10px; /* Add space on the right */
}

.order_information {
    display: flex;
    justify-content: space-between; /* Spread the items across */
    align-items: flex-start;
    font-family: Arial, sans-serif;
    padding: 5px 10px;
    background-color: #f5e1c5;
    border-radius: 8px;
    margin-bottom: 5px; /* Space between li elements */
    border: 1px solid #d2b48c;
    position: relative; /* For icon positioning */
}

.order_information::before {
    content: '';
    display: inline-block;
    width: 32px;
    height: 32px;
    background: url('https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png') no-repeat;
    margin-right: 10px;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: -40px; /* Adjust as needed for icon placement */
    transform: translateY(-50%);
}

/* Define each command type with its specific background position */
.order_information[data-command_type="abort"] {
  &::before {
    background-position: 0 0;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="attack_breakthrough"] {
  &::before {
    background-position: -32px 0;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="attack_incoming"] {
  &::before {
    background-position: 0 -32px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="attack_land"] {
  &::before {
    background-position: -32px -32px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="attack"] {
  &::before {
    background-position: -32px -32px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="attack_pillage"] {
  &::before {
    background-position: -64px 0;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="attack_portal_attack_olympus"] {
  &::before {
    background-position: -64px -32px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="attack_portal_support_olympus"] {
  &::before {
    background-position: 0 -64px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="attack_sea"] {
  &::before {
    background-position: -32px -64px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="attack_spy"] {
  &::before {
    background-position: -64px -64px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="attack_support"] {
  &::before {
    background-position: -96px 0;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="attack_takeover"] {
  &::before {
    background-position: -96px -32px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="colonization"] {
  &::before {
    background-position: -96px -64px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="colonization_failed"] {
  &::before {
    background-position: 0 -96px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="conquer"] {
  &::before {
    background-position: -32px -96px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="farm_attack"] {
  &::before {
    background-position: -64px -96px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="foundation"] {
  &::before {
    background-position: -96px -96px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="illusion"] {
  &::before {
    background-position: -128px 0;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="revolt"] {
  &::before {
    background-position: -128px -32px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="revolt_arising"] {
  &::before {
    background-position: -128px -64px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="revolt_running"] {
  &::before {
    background-position: -128px -96px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="siege"] {
  &::before {
    background-position: 0 -128px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="support"] {
  &::before {
    background-position: -32px -128px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}

.order_information[data-command_type="trade"] {
  &::before {
    background-position: -64px -128px;
  }

  &[glow="glow-red"] {
    &::before {
      box-shadow: 0px 0px 20px 5px #ff0000;
    }
    background: url("../images/row_highlight.png") repeat !important;
  }
}



.top-group {
    display: flex;
    align-items: center;
}

.bottom-group {
    display: flex;
    align-items: center; /* Align the time elements in one line */
}

.left-group {
    flex: 1; /* Takes up the remaining space on the left */
}

.order_units {
    display: flex;
    flex-direction: row; /* Ensure units are aligned horizontally */
    gap: 2px; /* Space between unit icons */
    font-weight: 700;
    color: white;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

.unit-icon {
    width: 32px;
    height: 32px;
    background-size: cover;
    border-radius: 5px;
}

.origin_and_destination {
    font-weight: bold;
    font-size: medium;
    color: #8b4513;
}

.destination_time_countdown {
    font-size: small;
    font-weight: bold;
    color: #333;
    margin-right: 10px;
}

.destination_time_arrival,
.destination_time_canncelable {
    font-size: small;
    color: #555;
}

.destination_time_arrival {
    margin-right: 5px;
}

.destination_time_canncelable {
    color: #6b8e23;
}


//---
.prediction-group {
  display: flex;  
  overflow: hidden;
}

.small {
  transform: scale(0.5);
  transform-origin: top left;
  //margin-bottom: -20px;
  width: 20px!important;
  height: 20px!important;
}

.unit-icon {
    width: 40px;
    height: 40px;
    background-color: #000;

    &[data-type='achilles'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) 0 0 no-repeat;
      }
      &[data-type='agamemnon'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -40px 0 no-repeat;
      }
      &[data-type='ajax'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) 0 -40px no-repeat;
      }
      &[data-type='alexandrios'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -40px -40px no-repeat;
      }
      &[data-type='andromeda'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -80px 0 no-repeat;
      }
      &[data-type='anysia'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -80px -40px no-repeat;
      }
      &[data-type='apheledes'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) 0 -80px no-repeat;
      }
      &[data-type='archer'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -40px -80px no-repeat;
      }
      &[data-type='argus'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -80px -80px no-repeat;
      }
      &[data-type='aristotle'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -120px 0 no-repeat;
      }
      &[data-type='atalanta'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -120px -40px no-repeat;
      }
      &[data-type='attack_ship'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -120px -80px no-repeat;
      }
      &[data-type='big_transporter'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) 0 -120px no-repeat;
      }
      &[data-type='bireme'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -40px -120px no-repeat;
      }
      &[data-type='calydonian_boar'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -80px -120px no-repeat;
      }
      &[data-type='catapult'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -120px -120px no-repeat;
      }
      &[data-type='centaur'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -160px 0 no-repeat;
      }
      &[data-type='cerberus'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -160px -40px no-repeat;
      }
      &[data-type='chariot'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -160px -80px no-repeat;
      }
      &[data-type='cheiron'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -160px -120px no-repeat;
      }
      &[data-type='christopholus'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) 0 -160px no-repeat;
      }
      &[data-type='colonize_ship'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -40px -160px no-repeat;
      }
      &[data-type='daidalos'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -80px -160px no-repeat;
      }
      &[data-type='deimos'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -120px -160px no-repeat;
      }
      &[data-type='democritus'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -160px -160px no-repeat;
      }
      &[data-type='demolition_ship'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -200px 0 no-repeat;
      }
      &[data-type='deryntes'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -200px -40px no-repeat;
      }
      &[data-type='ephialtes'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -200px -80px no-repeat;
      }
      &[data-type='eurybia'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -200px -120px no-repeat;
      }
      &[data-type='ferkyon'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -200px -160px no-repeat;
      }
      &[data-type='fury'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) 0 -200px no-repeat;
      }
      &[data-type='godsent'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -40px -200px no-repeat;
      }
      &[data-type='griffin'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -80px -200px no-repeat;
      }
      &[data-type='harpy'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -120px -200px no-repeat;
      }
      &[data-type='hector'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -160px -200px no-repeat;
      }
      &[data-type='helen'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -200px -200px no-repeat;
      }
      &[data-type='hercules'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -240px 0 no-repeat;
      }
      &[data-type='hoplite'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -240px -40px no-repeat;
      }
      &[data-type='iason'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -240px -80px no-repeat;
      }
      &[data-type='ladon'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -240px -120px no-repeat;
      }
      &[data-type='leonidas'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -240px -160px no-repeat;
      }
      &[data-type='lysippe'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -240px -200px no-repeat;
      }
      &[data-type='manticore'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) 0 -240px no-repeat;
      }
      &[data-type='medea'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -40px -240px no-repeat;
      }
      &[data-type='medusa'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -80px -240px no-repeat;
      }
      &[data-type='melousa'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -120px -240px no-repeat;
      }
      &[data-type='mihalis'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -160px -240px no-repeat;
      }
      &[data-type='militia'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -200px -240px no-repeat;
      }
      &[data-type='minotaur'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -240px -240px no-repeat;
      }
      &[data-type='odysseus'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -280px 0 no-repeat;
      }
      &[data-type='orpheus'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -280px -40px no-repeat;
      }
      &[data-type='pariphaistes'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -280px -80px no-repeat;
      }
      &[data-type='pegasus'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -280px -120px no-repeat;
      }
      &[data-type='pelops'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -280px -160px no-repeat;
      }
      &[data-type='perseus'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -280px -200px no-repeat;
      }
      &[data-type='philoctetes'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -280px -240px no-repeat;
      }
      &[data-type='rekonos'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) 0 -280px no-repeat;
      }
      &[data-type='rider'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -40px -280px no-repeat;
      }
      &[data-type='satyr'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -80px -280px no-repeat;
      }
      &[data-type='sea_monster'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -120px -280px no-repeat;
      }
      &[data-type='siren'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -160px -280px no-repeat;
      }
      &[data-type='slinger'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -200px -280px no-repeat;
      }
      &[data-type='small_transporter'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -240px -280px no-repeat;
      }
      &[data-type='spartoi'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -280px -280px no-repeat;
      }
      &[data-type='sword'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -320px 0 no-repeat;
      }
      &[data-type='telemachos'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -320px -40px no-repeat;
      }
      &[data-type='terylea'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -320px -80px no-repeat;
      }
      &[data-type='themistokles'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -320px -120px no-repeat;
      }
      &[data-type='theseus'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -320px -160px no-repeat;
      }
      &[data-type='trireme'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -320px -200px no-repeat;
      }
      &[data-type='unknown'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -320px -240px no-repeat;
      }
      &[data-type='unknown_hero'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -320px -280px no-repeat;
      }
      &[data-type='unknown_naval'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) 0 -320px no-repeat;
      }
      &[data-type='urephon'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -40px -320px no-repeat;
      }
      &[data-type='vedouma'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -80px -320px no-repeat;
      }
      &[data-type='xanthos'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -120px -320px no-repeat;
      }
      &[data-type='ylestres'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -160px -320px no-repeat;
      }
      &[data-type='zuretha'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -200px -320px no-repeat;
      }
      &[data-type='zyklop'] {
        background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/units/unit_icons_40x40_66aaef2.png) -240px -320px no-repeat;
      }
      &[data-type='payed_iron'] {
        background: url("../images/iron_40x40.png") no-repeat;
      }
  }

  .glow_red {
    box-shadow: -5px 2px 0px #ff0000;
  }

  .fade {
    transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out; /* Add height transition */
    opacity: 1; /* Fully visible by default */
    height: auto; /* Set to auto to take up space */
}

.fade-out {
    opacity: 0; /* Fully transparent */
    height: 0; /* Set height to 0 when fading out */
    pointer-events: none; /* Prevent interactions while fading out */
}