  .layout-container {
    display: flex; /* Use flexbox to position the sidebar and content side by side */
    height: 100vh; /* Full height of the viewport */
   }
  
  .sidebar-holder {
    width: 250px; /* Sidebar width */
    height: 100%; /* Full height of the viewport */
    position: fixed;
    top: 0;
    left: 0;
    background-color: #1a1a2e;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .main-content {
    margin-left: 290px; /* Offset the content by the sidebar width */
    flex-grow: 1; /* Make the content area take up the remaining space */
    background-color: #F3D192; /* Background color for the main content */
    overflow-y: auto; /* Scrollable content area if necessary */
  }
  