/* Prevent scrolling on the page */
body {
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

/* Style for the command-viewer container */
.command-viewer {
    display: flex;
    width: 100%;
    height: 100vh; /* Full viewport height */
    box-sizing: border-box;
    border: 2px solid #000; /* Border across the page */
}

/* Style for the command-filters section */
.command-filters {
    min-width: 200px; /* Adjust this value as needed */
    flex: 0 0 auto; /* Prevent flex from shrinking below min-width */
    box-sizing: border-box;
    border: 1px solid #000; /* Border around this section */
    padding: 10px;
    background-color: #f4f4f4; /* Light background color */
    overflow-y: auto; /* Allow vertical scrolling if content overflows */
}

/* Style for form elements in the filters section */
.command-filters input[type="text"] {
    width: calc(100% - 22px); /* Full width minus padding and border */
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.command-filters select {
    width: calc(100% - 22px); /* Full width minus padding and border */
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
    background-color: #fff;
    height: 120px; /* Fixed height for multi-select dropdown */
}

/* Style for the command-overview section */
.command-overview {
    flex: 1; /* 3/4 width */
    box-sizing: border-box;
    border: 1px solid #000; /* Border around this section */
    
    background-color: #f4f4f4; /* Light background color */
    display: flex;
    flex-direction: column;
}

/* Style for the command-overview-border */
.command-overview-border {
    border: 1px solid #000;
    box-sizing: border-box;
    flex: 1; /* Flex to take remaining space */
    display: flex;
    flex-direction: column;
    background-color: #fff; /* White background for contrast */
}

/* Style for the command-overview-title */
.command-overview-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid #ddd;
    background-image: url("../../images/header.png");
    
    color: #fff;
    font-weight: bold;
    font-size: large;
}

.command-overview-title .server-time,
.command-overview-title .title,
.command-overview-title .refresh-timer {
    flex: 1;
    text-align: center;
}

/* Style for the command-overview-body */
.command-overview-body {
    flex: 1; /* Take remaining space */
    overflow-y: auto; /* Allow vertical scrolling if content overflows */
    background-image: url("../../images/windowbackground.jpg");
}

//---

/* Filter label */
.filter-label {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Command types heading */
.command-types {
    font-size: 1.2rem;
    font-weight: bold;
    color: #444;
    margin-bottom: 10px;
}

/* Subheading for Alliance/Player/Town */
h4 {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #555;
}

/* Input text style */
input[type="text"] {
    width: calc(100% - 22px);
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 20px;
    box-sizing: border-box;
    font-size: 1rem;
    color: #333;

    &::placeholder {
        color: #999;
    }
}

/* Order limit section */
.order-limit {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #555;
}

/* Range input style */
input[type="range"] {
    width: 100%;
    margin-bottom: 20px;
    height: 6px;
    border-radius: 5px;
    background: #ddd;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #555;
    }

    &::-moz-range-thumb {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #555;
    }
}

//--
  .icon-selection {
    display: grid;
    grid-template-columns: repeat(5, 48px); //5 icon buttons per row
    grid-gap: 1px; 
  }

  .icon-selection-2 {
    display: grid;
    grid-template-columns: repeat(4, 48px); //5 icon buttons per row
    grid-gap: 1px; 
  }

  .attack_type32x32 {
    width: 32px;
    height: 32px;
    border: none;
    transform: scale(1.5);
    transform-origin: top left; /* Ensure scaling starts from the top-left */
    margin-bottom: 16px; /* Adjust margin to reduce the extra space created by scaling */
  }

  .unit-icon.active {
    opacity: 1;
  }
  .attack_type32x32.active {
    opacity: 1; /* Full opacity for active buttons */
  }

  .unit-icon.inactive {
    opacity: 0.4;
  }
  .attack_type32x32.inactive {
    opacity: 0.4; /* Reduced opacity for inactive buttons */
  }
  
  .attack_type32x32.abort {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat 0 0;
  }
  
  .attack_type32x32.attack_breakthrough {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -32px 0;
  }
  
  .attack_type32x32.attack_incoming {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat 0 -32px;
  }
  
  .attack_type32x32.attack_land {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -32px -32px;
  }
  .attack_type32x32.attack {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -32px -32px;
  }
  
  .attack_type32x32.attack_pillage {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -64px 0;
  }
  
  .attack_type32x32.attack_portal_attack_olympus {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -64px -32px;
  }
  
  .attack_type32x32.attack_portal_support_olympus {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat 0 -64px;
  }
  
  .attack_type32x32.attack_sea {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -32px -64px;
  }
  
  .attack_type32x32.attack_spy {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -64px -64px;
  }
  
  .attack_type32x32.attack_support {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -96px 0;
  }
  
  .attack_type32x32.attack_takeover {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -96px -32px;
  }
  
  .attack_type32x32.colonization {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -96px -64px;
  }
  
  .attack_type32x32.colonization_failed {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat 0 -96px;
  }
  
  .attack_type32x32.conquer {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -32px -96px;
  }
  
  .attack_type32x32.farm_attack {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -64px -96px;
  }
  
  .attack_type32x32.foundation {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -96px -96px;
  }
  
  .attack_type32x32.illusion {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -128px 0;
  }
  
  .attack_type32x32.revolt {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -128px -32px;
  }
  
  .attack_type32x32.revolt_arising {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -128px -64px;
  }
  
  .attack_type32x32.revolt_running {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -128px -96px;
  }
  
  .attack_type32x32.siege {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat 0 -128px;
  }
  
  .attack_type32x32.support {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -32px -128px;
  }
  
  .attack_type32x32.trade {
    background: url(https://gpnl.innogamescdn.com/images/game/autogenerated/unit_overview/attack_type_e52169f.png) no-repeat -64px -128px;
  }

  //Button
  .WebButton {
    background-color: #16213e; /* Custom deep blue background */
    color: #f5f5f5; /* White text color */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    padding: 10px 15px; /* Padding for top/bottom and left/right */
    font-size: 16px; /* Font size */
    font-weight: bold; /* Bold text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Transition for background and transform */
    
    &:hover {
      background-color: #3b5ab7; /* Darker shade on hover */
      transform: translateY(-2px); /* Slightly raise the button on hover */
    }
  
    &:active {
      background-color: #0b1429; /* Even darker shade when active */
      transform: translateY(0); /* Reset position on active */
    }
  
    &:disabled {
      background-color: #c0c0c0; /* Gray background for disabled state */
      color: #7a7a7a; /* Gray text color for disabled state */
      cursor: not-allowed; /* Not-allowed cursor for disabled state */
      pointer-events: none; /* Prevent interaction */
    }
  }