body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  h1.title {
    font-size: 7rem;
    text-decoration: underline;
    text-shadow: 0 0 0.5vw black;
  }
  
  input.login {
    padding: 12px;
    margin: 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    box-shadow: 0 1px 4px #ccc;
    border-radius: 6px;
    font-size: 18px;
    width: 93%;
  }
  
  button.submit {
    background-color: teal;
    border: none;
    color: white;
    padding: 12px;
    margin: 12px 0;
    box-shadow: 0 1px 6px #ccc;
    width: 100%;
    border-radius: 6px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .container {
    box-shadow: 0 1px 6px #ccc;
    border-radius: 6px;
    width: 400px;
    padding: 22px;
    background-color: white;
    position: absolute;
    top: 35%;
    opacity: 90%;
    background-color: rgb(206, 218, 228);
  }
  
  .form > h2 {
    text-align: center;
    text-decoration: underline;
    text-shadow: 0 0 0.3vw black;
  }
  
  .form > form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .error {
    background-color: tomato;
    color: white;
    border-radius: 6px;
    padding: 6px 12px;
    font-weight: bold;
    max-width: 400px;
    margin-bottom: 18px;
  }

  